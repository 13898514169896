@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: "Poppins", sans-serif;
}

.hero2 {
  background-image: url(/static/media/bg.f0da3ed4.jpg);
  background-size: cover;
  background-position-x: 500px;
  background-repeat: no-repeat;
  background-color: rgb(0, 255, 255);
}

.title2 {
  color: white;
  font-size: 60px;
  letter-spacing: 5px;
}
.subtitle2 {
  color: white;
  letter-spacing: 5px;
}
.navbar-item {
  text-transform: uppercase;
  font-size: smaller;
  letter-spacing: 3px;
}
.navbar-item:hover {
  color: cyan;
}
.notice {
  color: darkcyan;
  letter-spacing: 2px;
}
#tile {
  background-image: url(/static/media/bg2.f17b3c79.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
#tile2 {
  background-image: url(/static/media/bg2.f17b3c79.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.con {
  background-color: white;
  padding: 15px;
  display: flex;
  flex-direction: row;
  margin-top: 100px;
  border-radius: 7px;
  /* justify-content: space-between; */
  align-items: center;
}
.con2 {
  background-color: white;
  padding: 15px;
  display: flex;
  flex-direction: row;
  border-radius: 7px;
  align-items: center;
  margin-top: 100px;
}
.icon {
  width: 70px;
  height: 70px;
  background-color: #fafafa;
  border-radius: 7px;
  margin-right: 10px;
  padding: 15px;
}
.detail {
  font-size: smaller;
}
.name {
  font-weight: bold;
  color: darkcyan;
}
.city,
.price {
  font-size: x-small;
  color: gray;
}

/* MOBILE */
@media only screen and (max-width: 770px) {
  .hero2 {
    background-image: url(/static/media/bg.f0da3ed4.jpg);
    background-size: cover;
    background-position-x: 100px;
    background-repeat: no-repeat;
    background-color: rgb(0, 255, 255);
  }
  .title2 {
    color: white;
    font-size: 50px;
    letter-spacing: 5px;
  }
  .subtitle2 {
    color: white;
    letter-spacing: 5px;
    font-size: smaller;
  }
  .notice {
    color: darkcyan;
    letter-spacing: 2px;
    font-size: 10px;
  }
  .disc {
    display: none;
  }
}

@media only screen and (min-width: 770px) {
  .dis-con {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: -60px;
    margin-bottom: -60px;
  }
  .discount {
    width: 50%;
  }
  .disc {
    font-size: 200px;
    font-weight: bolder;
    transform: rotate(10deg);
  }
  .contact-con {
    display: flex;
    justify-content: space-between;
  }
  .contact {
    width: 50%;
  }
  .social {
    width: 50%;
  }
  .social img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    margin-top: -10px;
  }
}
.social img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  margin-top: -10px;
}
